<template>
  <div>
    <b-card v-if="!slim">
      <template #header>
        <card-header
          :title="label"
          :subtitle="docPoints"
          v-if="!slim && !headerAlt"
        />
      </template>

      <label
        class="col-form-label col-form-label--accent"
        v-if="!slim && headerAlt"
      >
        {{label}}
      </label>
      <div>
        <b-form-group :label="labelPlaceholder">
          <b-input-group class="input--alt-style">
            <b-form-input
              type="text"
              :value="documentName"
              disabled
            />
            <b-input-group-append>
              <b-button
                class="btn-info ms-1"
                @click="openInfo"
                v-if="hasInfo"
                v-b-tooltip.hover.left
                title="Informazioni aggiuntive"
              >
                <font-awesome-icon icon="info-circle" class="fa-fw"></font-awesome-icon>
              </b-button>
              <b-button
                class="btn-info ms-1"
                @click="onClick"
                :disabled="readOnly"
                v-if="hasUpload"
                v-b-tooltip.hover
                title="Upload"
              >
                <font-awesome-icon icon="cloud-upload-alt" class="fa-fw"></font-awesome-icon>
              </b-button>
              <b-button
                class="btn-info ms-1"
                @click="downloadFile"
                v-if="hasDownload"
                v-b-tooltip.hover
                title="Download"
              >
                <font-awesome-icon icon="cloud-download-alt" class="fa-fw"></font-awesome-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <p v-if="hasInfo" v-html="documentInfo" />
      </div>
    </b-card>
    <div v-if="slim">
      <label
        class="col-form-label col-form-label--accent"
        v-if="!slim && headerAlt"
      >
        {{label}}
      </label>
      <div>
        <b-form-group :label="labelPlaceholder">
          <b-input-group class="input--alt-style">
            <b-form-input
              type="text"
              :value="documentName"
              disabled
            />
            <b-input-group-append>
              <b-button
                class="btn-info ms-1"
                @click="openInfo"
                v-if="hasInfo"
                v-b-tooltip.hover
                title="Informazioni aggiuntive"
              >
                <font-awesome-icon icon="info-circle" class="fa-fw"></font-awesome-icon>
              </b-button>
              <b-button
                class="btn-info ms-1"
                @click="onClick"
                :disabled="readOnly"
                v-if="hasUpload"
                v-b-tooltip.hover
                title="Upload"
              >
                <font-awesome-icon icon="cloud-upload-alt" class="fa-fw"></font-awesome-icon>
              </b-button>
              <b-button
                class="btn-info ms-1"
                @click="downloadFile"
                v-if="hasDownload"
                v-b-tooltip.hover
                title="Download"
              >
                <font-awesome-icon icon="cloud-download-alt" class="fa-fw"></font-awesome-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <p v-if="hasInfo" v-html="documentInfo" />
      </div>
    </div>
    <modal-doc-info :id="modalInfoId" :info="info" />
  </div>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import { getEntityApiBase, openPrivateDocument } from '@/utils/documents';
import { DOCUMENT_CLASS } from '@/utils/interfaces';

const ModalDocInfo = () => import('@/components/documents/ModalDocInfo.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'DocumentUpload',
  props: {
    enableUpload: {
      default: null,
      type: Boolean,
    },
    onClick: Function,
    entityType: String,
    readOnly: Boolean,
    document: {
      ...DOCUMENT_CLASS,
    },
    slim: Boolean,
    headerAlt: Boolean,
  },
  components: { ModalDocInfo, CardHeader },
  computed: {
    hasDownload() {
      return isPresent(this.document.documentUpload?.name);
    },
    hasInfo() {
      return isPresent(this.$store.getters['documents/documentInfo'](this.document));
    },
    info() {
      return this.$store.getters['documents/documentInfo'](this.document);
    },
    hasPoints() {
      return isPresent(this.$store.getters['documents/documentPoints'](this.document));
    },
    points() {
      return this.$store.getters['documents/documentPoints'](this.document);
    },
    label() {
      return this.$store.getters['documents/decodeDocumentName'](this.document);
    },
    documentName() {
      return this.document.documentUpload?.name || 'Documento non ancora caricato';
    },
    documentInfo() {
      return this.$store.getters['documents/documentInfo'](this.document);
    },
    labelPlaceholder() {
      if (this.slim) {
        return this.label;
      }
      return '';
    },
    hasUpload() {
      if (isNotEmpty(this.enableUpload)) {
        return this.enableUpload;
      }
      const status = this.$store.getters['subject/activityPersonStatus'];
      if (status.waitApproval || status.operative || status.notOperative) {
        if (isPresent(this.document.documentUpload.name)) return false;
      }
      return true;
    },
    modalInfoId() {
      return `info_${this.document.documentId}`;
    },
    docPoints() {
      return this.hasPoints ? `PUNTI: ${this.points}` : '';
    },
  },
  methods: {
    downloadFile() {
      openPrivateDocument(this.document.documentId, getEntityApiBase(this.entityType));
    },
    openInfo() {
      this.$bvModal.show(this.modalInfoId);
    },
  },
};
</script>

<style scoped>

</style>
